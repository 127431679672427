import _ from 'lodash'
import axios from '../../common/axios.js'
import GraphView from './graphView.vue';

export default {
	data() {
		return {
			data: null,
			error: null
		}
	},
	props: [
		'type'
	],
	created(){
		
	},
	mounted(){
		this.getData()
	},
	watch:{
		"$store.state.entreprises.graphs_assolement": function(){
			this.getData()
		}
	},
	methods: {
		getData(){

			//AS--> Y'a-t-il une erreur ?
			if(!this.$store.state.entreprises.graphs_assolement || this.$store.state.entreprises.graphs_assolement == 'error' || !this.$store.state.entreprises.graphs_assolement['SurfaceCulture'+this.type] || !this.$store.state.entreprises.graphs_assolement['SurfaceCulture'+this.type].stats?.length){
				this.error = this.$store.state.entreprises.graphs_assolement
				this.data = null
			}else{
				this.data  = this.$store.state.entreprises.graphs_assolement && this.$store.state.entreprises.graphs_assolement['SurfaceCulture'+this.type] ? this.$store.state.entreprises.graphs_assolement['SurfaceCulture'+this.type] : null
				this.error = null
			}
		}
	},
	computed:{
		params(){
			if(!this.data || !this.data.stats) return null
				
			let data = [];

			_.forEach(this.data.stats, function(stat){
				data.push({
			 		titre: 		stat.titre,
					name: 	 stat.titre,
					y: 		 Math.round(stat.surface*10)/10,
					percent: Math.round(stat.pourcent*10)/10
				});
			});
			return {
				chart: {
					type: 'column'
				},
			    credits: {
					enabled: false
				},
				title: {
					text: this.$t('graph_assolement_titre_'+this.type)
				},
				legend: {
					enabled: false
				},
			    tooltip: {
			        pointFormat: '{point.y}ha / {point.percent}%'
			    },
			    plotOptions: {
			        pie: {
			            allowPointSelect: true,
			            cursor: 'pointer'
			        }
			    },
				xAxis: {
					type: 'category',
					labels: {
						rotation: -45
					}
				},
				yAxis: {
					min: 0,
					// max: 100,
					title: {
						text: this.$t('graph_assolement_surface')
					}
				},
				series: [{
					name: this.$t('graph_assolement_surface'),
					colorByPoint: true,
					data: data,
			        dataLabels: {
						enabled: false,
						// format: '{point.percent}%'
					}
				}]
			}
		},
		errors(){
			let error = null
			
			if(this.error){

				//AS--> Cas de l'absence de filtre
				if(this.error.detail?.length && this.error.detail[0].msg == 'pas de filtre'){
					error = this.$t('graph_assolement_error_limit')
				}

				//AS--> Cas du chargement
				else if(this.error == null){
					error = this.$t('chargement')
				}

				//AS--> Cas d'une erreur identifiée
				else if(this.error.detail?.length){

					//AS--> Cas d'un rayon trop grand
					if(this.error.detail[0].loc?.length && this.error.detail[0].loc[1] == 'radius' && this.error.detail[0].type == "less_than_equal"){
						error = this.$t('graph_assolement_error_limit')

						if(this.error.detail[0].ctx?.limit_value){
							error = this.$t('graph_assolement_error_limit_range').replace('@rayon', this.error.detail[0].ctx.limit_value)
						}

					//AS--> Cas d'une zone trop grande
					}else if(this.error.detail[0].loc?.length && this.error.detail[0].loc[1] == 'zones_filter' && this.error.detail[0].type == "less_than_equal"){
						error = this.$t('graph_assolement_error_limit')

					//AS--> Cas d'une erreur inconnue
					}else{
						error = this.$t('error')
					}

				}

				else if(!this.data?.stats?.length){
					error = this.$t('graph_assolement_nodata_'+this.type)
				}
			}
			return error
		}
	},
	components: {
		GraphView
	}
}